<template>
    <div>
        <function-entry></function-entry> 
        <panel-group
              group-uid="68d3d452"
              :show-title="false"
        ></panel-group>

    </div>
</template>
<script>
import FunctionEntry from "../../../../cabr/dashboard/components/FunctionEntry.vue";
import PanelGroup from "@/views/panel-group";

export default{
    name: "PhyMonitor",
    components:{
        FunctionEntry,
        PanelGroup,
    },
    setup(){

    }
}
</script>
<style lang="scss" scoped></style>